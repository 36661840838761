import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import PrivacyPolicyPage from './pages/PrivacyPolicy';
import CookiePolicyPage from './pages/CookiePolicy';
import AboutUs from './pages/AboutUs';
import Blog from './pages/Blog';
import BlogArticle from './pages/BlogArticle';
import BlogArticle2 from './pages/BlogArticle2';
import BlogArticle3 from './pages/BlogArticle3';
import BlogArticle4 from './pages/BlogArticle4';
import BlogArticle5 from './pages/BlogArticle5';
import BlogArticle6 from './pages/BlogArticle6';
import BlogArticle7 from './pages/BlogArticle7';
import BlogArticle8 from './pages/BlogArticle8';
import BlogArticle9 from './pages/BlogArticle9';
import BlogArticle10 from './pages/BlogArticle10';
import BlogArticle11 from './pages/BlogArticle11';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route Component={() => <HomePage />} path="/" exact />
                <Route Component={() => <AboutUs />} path="/about" exact />
                <Route Component={() => <Blog />} path="/blog" exact />
                <Route Component={() => <BlogArticle />} path="/blog/threat-insights-2024-30" exact />
                <Route Component={() => <BlogArticle2 />} path="/blog/threat-insights-2024-31" exact />
                <Route Component={() => <BlogArticle3 />} path="/blog/threat-insights-2024-32" exact />
                <Route Component={() => <BlogArticle4 />} path="/blog/threat-insights-2024-33" exact />
                <Route Component={() => <BlogArticle5 />} path="/blog/threat-insights-2024-34" exact />
                <Route Component={() => <BlogArticle6 />} path="/blog/threat-insights-2024-36" exact />
                <Route Component={() => <BlogArticle7 />} path="/blog/threat-insights-2024-37" exact />
                <Route Component={() => <BlogArticle8 />} path="/blog/threat-insights-2024-38" exact />
                <Route Component={() => <BlogArticle9 />} path="/blog/threat-insights-2024-39" exact />
                <Route Component={() => <BlogArticle10 />} path="/blog/threat-insights-2024-40" exact />
                <Route Component={() => <BlogArticle11 />} path="/blog/threat-insights-2024-41" exact />
                <Route Component={() => <PrivacyPolicyPage />} path="/privacy-policy" exact />
                <Route Component={() => <CookiePolicyPage />} path="/cookie-policy" exact />
                <Route Component={() => <HomePage />} path="*" exact />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
